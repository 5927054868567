import { ACTION_TYPES } from "../../constant/actionTypes";

/**
 * Action to get ASYNC the theme
 */
export function themeLoadCurrent() {
  return {
    type: ACTION_TYPES.THEME.ASYNC.GET
  };
}

/**
 * Action to dispatch the theme
 * @param {object} success data from the server
 */
export function themeCurrent(success) {
  return {
    type: ACTION_TYPES.THEME.WORKER.LOAD_CURRENT_THEME,
    payload: {
      ...success
    }
  };
}
