import { ACTION_TYPES } from "../../constant/actionTypes";
import { BASE_SITE, ENV_THEME } from "../../constant/base";

console.log(ENV_THEME);

export const initialState = {
  logo: BASE_SITE + "/assets/img/logo.svg",
  icon: BASE_SITE + "/assets/img/icono.svg",
  background_image_login: BASE_SITE + "/assets/img/back.png",

  background_color_body: "white",
  primary_color: "#00BF84",
  primary_color_text_over: "white",
  primary_color_alt: "white",
  primary_color_alt_text_over: "black",

  secondary_color: "#545454",
  secondary_color_text_over: "#4a4e51",

  side_bar_color: "white",
  side_bar_color_over: "white",
  side_bar_color_text_over: "#B2AFC0",
  side_bar_color_active: "green",
  side_bar_color_active_text_over: "white",
  side_bar_shadow: "20px 0 30px 0 rgba(0, 0, 0, 0.03)",

  success_color: "#22bb33",
  success_color_text_over: "white",
  info_color: "#5bc0de",
  info_color_text_over: "white",
  cancel_color: "#71767B",
  cancel_color_text_over: "white",
  danger_color: "#FF0000",
  danger_color_text_over: "white",
  warning_color: "#ff9933",
  warning_color_text_over: "white",

  background_image: BASE_SITE + "/img/to_pay_01_compress.png",
  background_image_error: BASE_SITE + "/img/404-500.png",

  pse_color: "#2557A0",

  country: "co",
  timezone: "America/Bogota",
  terminos: null,
  transaction_version:"0",
  historico:false,
  ...ENV_THEME,
};
 

export default function ThemeReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.THEME.WORKER.LOAD_CURRENT_THEME:
      return changeTheme({ ...state }, action.payload);
    default:
      return state;
  }
}

const changeTheme = (stateTheme = undefined, argTheme) => {
  let theme = { ...stateTheme, ...argTheme };
  return theme;
};
