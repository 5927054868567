import { ACTION_TYPES } from "../../constant/actionTypes";

export function getServerStatus(data) {
  return {
    type: ACTION_TYPES.STATUS.ASYNC.GET_SERVER_STATUS,
    payload: {
      data
    }
  };
}

export function setServerStatus(data) {
  return {
    type: ACTION_TYPES.STATUS.WORKER.SET_SERVER_STATUS,
    payload: {
      ...data
    }
  };
}
