import React, { useEffect, useState } from "react";
import {setLocale, translate, I18n }  from 'react-i18nify';
import { connect } from "react-redux";

const langContext = React.createContext();

let globalValue = {};
export const getGlobalValue = () => globalValue;

const idiomas = [ "es", "pt"]

const lenguajeNavegador= (navigator.language).substring(0,2);
let lang;
if ( idiomas.includes(lenguajeNavegador) ) {
    lang = lenguajeNavegador;
} else {
    lang = "en";
}

const LangProvider =({children, theme} ) =>{
    const firstLanguage= theme.base_language  || lang;
    const [locale, establecerLocale] = useState(firstLanguage);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() =>{
        establecerLocale(firstLanguage)
    }, [firstLanguage])

    useEffect(() => {
        globalValue = locale
    }, [locale])
 
    const toggling = () => setIsOpen(!isOpen);
    
    const establecerLenguaje =(lenguaje) => {
        setIsOpen(false);
        switch (lenguaje){
            case 'es':
                setSelectedOption(<I18n render={() => <span>{translate("menu.spanish")}</span>} />);
                establecerLocale("es")
                setLocale("es");
                break;
            case 'en':
                setSelectedOption(<I18n render={() => <span>{translate("menu.english")}</span>} />);
                establecerLocale("en")
                setLocale("en");
                break;
            case 'pt':
                setSelectedOption(<I18n render={() => <span>{translate("menu.portuguese")}</span>} />);
                establecerLocale("pt")
                setLocale('pt');
                break;
            default:
                setSelectedOption(`${translate("menu.spanish")}`);
                establecerLocale("es")
        }
    }
    setLocale(locale)
    return(
        <langContext.Provider value={{establecerLenguaje: establecerLenguaje, toggling: toggling, isOpen: isOpen, selectedOption:selectedOption, locale}}>
            {children}
        </langContext.Provider>
    );
}

const mapStateToProps = state => ({
    theme:{ ...state.theme },
  });

export const LangContextTheme = connect(mapStateToProps )(LangProvider);

export{langContext}
