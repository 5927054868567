import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  barData: null,
  barLoading :false,
  statusBarData: null,
  statusBarLoading :false,
  statusRejectData: null,
  statusRejectLoading :false,
  transactionCarrierData: null,
  transactionCarrierLoading :false,
  statusAppCodeData: null,
  statusAppCodeLoading :false,
  transactionActivityData: null,
  transactionActivityLoading :false,
};

export const AnalitycsReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_OVER_TIME:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_MONEY:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_REJECT:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_CARRIER:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_APP_CODE:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_ACTIVITY:
      return { ...state, ...action.payload };
    case ACTION_TYPES.ANALITYCS.BASIC.INIT:
      return { ...initialState };
    default:
      return state;
  }
};