import { ACTION_TYPES } from "../../constant/actionTypes";

export function getReport(data) {
  return {
    type: ACTION_TYPES.REPORTS.ASYNC.GET_REPORTS,
    payload: {
      data
    }
  };
}

export function setReport(data) {
  return {
    type: ACTION_TYPES.REPORTS.WORKER.SET_REPORTS,
    payload: {
      ...data
    }
  };
}


export function setReportInit() {
  return {
    type: ACTION_TYPES.REPORTS.BASIC.INIT,
  };
}
