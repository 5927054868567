import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require('qs');


export const fetchServerStatus = (parametros, token) => {
    return Axios.get(`${ENDPOINTS.STATUS.SERVER_STATUS}`,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: parametros,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};