import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  loadingRegister: false,
  typeCommerce: [],
  typeDoc: [],
  depto: [],
  cities: [],
  questions: {},
  user: {}
}

export const RegisterReducers = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_COMMERCE:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.TYPE_COMMERCE:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_DOC:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.TYPE_DOC:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_DEPTO:
        return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.LIST_DEPTO:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_CITIES:
        return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.LIST_CITIES:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_QUESTIONS:
        return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.QUESTIONS:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.ASYNC.GET_REGISTER_USER:
        return { ...state, ...action.payload };
    case ACTION_TYPES.REGISTERDB.WORKER.REGISTER_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

