import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  reportLoading: false,
  reportStatus: {}
};

export const ReportStatusReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.REPORTS.WORKER.SET_REPORTS_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};