import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
    formLoading: false,
    formValues: {},
    formRdb: {},
    tableLoading: false,
    statusTable: null,
    dataTransactions: {},
    loadingIdLinkToPay: false,
    idDetail: null
};

export const LinkToPayReducer = function(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.LINKTOPAY.SET_LINKTOPAY:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.WORKER.LOAD_FORM:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.ASYNC.FORM_REDEBAN:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.WORKER.SET_REDEBAN:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.WORKER.SET_TRANSACTIONS:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.WORKER.SET_PAYMENT_REFERENCE:
            return {...state, ...action.payload };
        case ACTION_TYPES.LINKTOPAY.BASIC.INIT_PAYMENT_REFERENCE:
            return {...state, ...{loadingIdLinkToPay: false, idDetail: null} };
        case ACTION_TYPES.LINKTOPAY.BASIC.INIT:
            return {...initialState };
        default:
            return state;
    }
};