import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require("qs");

export const fetchTableTransactions = (parametros, token,language ) => {
  const output = `?language=`;
  return fetchTable(
    `${ENDPOINTS.TRANSACTIONS.TABLE_TRANSACTIONS}`+ output + language,
    parametros,
    token
  );
};

export const fetchTable = (url, parametros, token) => {
  return Axios.get(url, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    params: parametros,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};
export const fetchReport = (url, parametros, token, language) => {
  const lang ={
    ...parametros,
    language
  }
  return Axios.get(url, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    responseType:'blob',
    credentials: "same-origin",
    params: lang,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const fetchRefundTransaction = (id, amount, token) => {
  const amountUrl = amount !== undefined ? "?amount=" + amount : "";
  return fetchTransactionFunction(
    `${ENDPOINTS.TRANSACTIONS.REFUND_TRANSACTION}` + id + "/refund" + amountUrl,
    token
  );
};

export const fetchLogTransaction = (id, token) => {
  return fetchTransactionFunction(
    `${ENDPOINTS.TRANSACTIONS.LOG_TRANSACTION}` + id + "/log",
    token
  );
};

export const fetchstatusHistory = (id, token, language) => {
  const output = `?language=`;
  return fetchTransactionFunction(
    `${ENDPOINTS.TRANSACTIONS.HISTORY_STATUS}`+ id + output + language,
    token, 
  );
};

export const fetchTransactionFunction = (url, token) => {
  return Axios.get(url, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  });
};

export const fetchTransactionPdfFunction = (id, parametros, token, language)=> {
  return fetchReport(
        `${ENDPOINTS.TRANSACTIONS.PDF_TRANSACTION}`+id + "/pdf",
        parametros,
        token,
        language,
  )
}
