import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../model/rootReducer";
import { PROCESS_ENV } from "../constant/base";

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
export const reactRouterMiddleware = routerMiddleware(history);
const confMiddleware = [
  // Add other middleware on this line...
  sagaMiddleware,
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
  thunk,
  reactRouterMiddleware
];

function configureStoreProd(initialState) {
  const middlewares = [...confMiddleware];
  return createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev(initialState) {
  const middlewares = [
    ...confMiddleware,
    logger,
    reduxImmutableStateInvariant()
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

const configureStore =
  PROCESS_ENV === "production" ? configureStoreProd : configureStoreDev;

export default configureStore;
