import { JWT_SECRET } from "../../constant/base";
import jwt from "jsonwebtoken";

export const authData = (token) => {
  try {
    return jwt.verify(token, JWT_SECRET);
  } catch (error) {
    return null;
  }
};
