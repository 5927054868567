import { select } from 'redux-saga/effects'; 

import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fetchServerStatus } from "../../services/serverSatusService";
import { setServerStatus } from "./statusAction";;


export const getAuth = (state) => state.auth;

export function* SERVER_STATUS({ payload }) {
    yield putAction(setServerStatus({statusLoading : true}));
    yield putAction(setServerStatus({statusData : {}}));
    let params = payload.data;
    let statusData = {};
    try {
        const auth = yield select(getAuth);
        const result = yield call(fetchServerStatus, params, auth.token);
        if (result && result.data && result.data.data && result.data.data.values) {
            //reportData = result.datax
            statusData = result.data.data.values;
        }
        
    }catch(error){}
    yield putAction(setServerStatus({statusLoading : false}));
    yield putAction(setServerStatus({statusData : statusData}));
}

export default function* ServerStatusSaga() {
  yield all([
    takeLatest(ACTION_TYPES.STATUS.ASYNC.GET_SERVER_STATUS, SERVER_STATUS),
  ]);
}

