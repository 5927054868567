import { ACTION_TYPES } from "../../constant/actionTypes";

// Acciones sobre la consulta de transacciones
export function getTableTransactions(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.ASYNC.GET_TRANSACTIONS,
    payload: {
      data,
    },
  };
}
export function getPdfTransaction(data){
  return{
    type: ACTION_TYPES.TRANSACTIONS.ASYNC.GET_PDF_TRANSACTION,
    payload: {
      data,
    },
  };
}
export function setTable(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.WORKER.SET_TRANSACTIONS,
    payload: {
      ...data,
    },
  };
}
export function setInitTable() {
  return {
    type: ACTION_TYPES.TRANSACTIONS.BASIC.INIT,
  };
}

// Acciones sobre el refund
export function refundTransaction(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.ASYNC.GET_REFUND_TRANSACTION,
    payload: {
      data,
    },
  };
}
export function setRefund(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.WORKER.SET_REFUND_TRANSACTION,
    payload: {
      ...data,
    },
  };
}
export function initRefundTransaction() {
  return {
    type: ACTION_TYPES.TRANSACTIONS.BASIC.INIT_REFUND_TRANSACTION,
  };
}

// Acciones sobre el log de una transaccion
export function logTransaction(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.ASYNC.GET_LOG_TRANSACTION,
    payload: {
      data,
    },
  };
}
export function statusHistory(data){
  return{
    type: ACTION_TYPES.TRANSACTIONS.ASYNC.GET_STATUS_HISTORY,
    payload:{
      ...data,
    }
  }
}
export function setLog(data) {
  return {
    type: ACTION_TYPES.TRANSACTIONS.WORKER.SET_LOG_TRANSACTION,
    payload: {
      ...data,
    },
  };
}
export function initLogTransaction() {
  return {
    type: ACTION_TYPES.TRANSACTIONS.BASIC.INIT_LOG_TRANSACTION,
  };
}
