import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require('qs');

export const fetchTypeCommerce = (parametros) => {
  return fetchCommerce(`${ENDPOINTS.REGISTRORDB.TYPE_COMMERCERDB}`, parametros);
};

export const fetchCommerce = (url, parametros) => {
  return Axios.get(url,
    {
      headers: {
        // Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: parametros,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};

export const fetchTypeDoc = (parametros) => {
  return fetchDocument(`${ENDPOINTS.REGISTRORDB.TYPE_DOCRDB}`, parametros);
};

export const fetchDocument = (url, parametros) => {
  return Axios.get(url,
    {
      headers: {
        // Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: parametros,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};

export const fetchNameDepto = (parametros) => {
  return fetchDepto(`${ENDPOINTS.REGISTRORDB.LIST_DEPTODRB}`, parametros);
};

export const fetchDepto = (url, parametros) => {
  return Axios.get(url,
    {
      headers: {
        // Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: parametros,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};

export const fetchListCities = (idDepto) => {
  return fetchCities(`${ENDPOINTS.REGISTRORDB.GET_CITIES}/${idDepto}`);
};

export const fetchCities = (url) => {
  return Axios.get(url,
    {
      headers: {
        // Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      timeout: 90000,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};

export const fecthListQuestions = (request) => {
	return Axios.post(
		`${ENDPOINTS.REGISTRORDB.GET_QUESTIONS}`,
		{
      type_document: request.type_document,
      document_number: request.document_number,
      date: request.date,
      phone: request.phone,
      state: request.state,
      city: request.city,
      last_name: request.last_name,
      second_last_name : request.second_last_name,
      name: request.name,
      second_name: request.second_name,
    },
		{
			headers: {
				// "Authorization": 'Bearer '.concat(token),
				"Content-Type": "application/json"
			},
			// credentials: "same-origin"
      timeout: 90000,
		}
	);
};

export const fecthValidateQuestions = (request) => {
  return Axios.post(
		`${ENDPOINTS.REGISTRORDB.VALIDATE_QUESTIONS}`,
		request,
		{
			headers: {
				"Content-Type": "application/json"
			},
      timeout: 90000,
		}
	);
}

export const fecthRegisterUser = (request) => {
	return Axios.post(
		`${ENDPOINTS.REGISTRORDB.REGISTER_USER}`,
		{
      type_commerce: request.type_commerce,
      name: request.name,
      second_name: request.second_name,
      last_name: request.last_name,
      rut: request.rut,
      nit: request.nit,
      fiscal_name: request.fiscal_name,
      type_document: request.type_document,
      document_number: request.document_number,
      date: request.date,
      phone: request.phone,
      state: request.state,
      city: request.city,
      category: request.category_code,
      billing: request.billing,
      address: request.address,
      type_phone: request.type_phone,
      products: request.products,
      operador: request.operador,
      bank: request.bank,
      account_type: request.account_type,
      account_number: request.account_number,
      cedula_url: request.cedula_url,
      cedula_reverso_url: request.cedula_reverso_url,
      rut_url: request.rut_url,
      chamber_commerce_url: request.chamber_commerce_url,
      captcha: request.captcha,
      second_last_name : request.second_last_name,
	    email : request.email,
      commerce_phone : request.commerce_phone,
      referido: request.referido
    },
		{
			headers: {
				// "Authorization": 'Bearer '.concat(token),
				"Content-Type": "application/json"
			},
			// credentials: "same-origin"
      timeout: 90000,
		}
	);
};


export const fetchCategory = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.CATEGORY}`, parametros);
};
export const fetchBilling = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.BILLING}`, parametros);
};
export const fetchReferido = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.REFERIDO}`, parametros);
};
export const fetchTypePhone = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.TYPE_PHONE}`, parametros);
};
export const fetchBank = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.BANK}`, parametros);
};
export const fetchTypeAccount = (parametros) => {
  return fetchCatalog(`${ENDPOINTS.REGISTRORDB.TYPE_ACCOUNT}`, parametros);
};

export const fetchCatalog = (url, parametros) => {
  return Axios.get(url,
    {
      headers: {
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: parametros,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};


export function uploadFile(datos) {
  return Axios.post(
		`${ENDPOINTS.REGISTRORDB.UPLOAD_FILE}`,
		datos,
		{
			headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 90000,
      withCredentials: false,
		  credentials: 'same-origin',
		  crossdomain: true,
      maxContentLength: 52428890,
      maxBodyLength: 52428890
		}
	);
}