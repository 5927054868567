import moment from 'moment';
import { select } from 'redux-saga/effects'; 

import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fetchCreateReports } from "../../services/reportsService";
import { setReport } from "./reportsAction";
import { getGlobalValue } from '../../../context/langContext';


export const getAuth = (state) => state.auth;

export function* REPORT_TRANSACTIONS({ payload }) {
    const connectionLanguage = getGlobalValue();
    yield putAction(setReport({reportLoading : true}));
    yield putAction(setReport({reportMessages : null}));
    let params = getParams(payload.data);
    let separator = payload.data.separator
    // si es todas las colunas  no enviamos datos de columnas
    if(payload.data.all) {
        params["columns"] = [];
        params["columns_default"] = [];
    } else {
        //si hay columnas extras seleccionadas  las concatenamos con las defalut
        if(params["columns"]) {
            params["columns"] = params["columns_default"].concat(params["columns"]);
        } else {
            // si se piden columnas sin extra se mandan las default
            params["columns"] = params["columns_default"]
        }
        params["columns_default"] = [];
    }
    try {
        const auth = yield select(getAuth);
        const result = yield call(fetchCreateReports, params, auth.token, connectionLanguage, separator);
        if (result && result.data && result.data.data && result.data.data.msg && result.data.data.msg ==="Report too long") {
            yield putAction(setReport({reportMessages : "tooLong"}));
        } else if (result && result.data && typeof result.data === 'string') {
            let nombreReporte = "report.csv"
            if(params["name"]) {
                nombreReporte =  params["name"]+".csv"
            }
            let csv = 'data:text/csv;charset=utf-8,' + result.data
            var contenido = encodeURI(csv)
            var link = document.createElement('a')
            link.setAttribute('href', contenido)
            link.setAttribute('download', nombreReporte)
            link.click()
            yield putAction(setReport({reportMessages : "ok"}));
        } else {
            yield putAction(setReport({reportMessages : "error"}));
        }
        
    }catch(error){
        yield putAction(setReport({reportMessages : "error"}));
    }
    yield putAction(setReport({reportLoading: false}));
    //yield putAction(setReport({reportTransactions: reportData }));
}

export default function* ReportsSaga() {
  yield all([
    takeLatest(ACTION_TYPES.REPORTS.ASYNC.GET_REPORTS, REPORT_TRANSACTIONS),
  ]);
}

function getParams(data, type) {
    const typeData = data.statusDate.value
  function changeValue(valor){
    etiquetasDates.dateEnd=`${valor}_lt`;
    etiquetasDates.dateStart=`${valor}_gt`;
  Object.assign(etiquetas, etiquetasDates);
  }
  changeValue(typeData)
    let params = {}
    for (let [key, value] of Object.entries(data)) {
        if(etiquetas[key] && value) {
            if(key === 'dateEnd' || key === 'dateStart') {
                params[etiquetas[key]] = moment(value).format('YYYY-MM-DD HH:mm');
            } else if(key === 'appCode') {
                let valores = [];
                for(let appC of value) {
                    valores.push(appC.value)
                }
                params[etiquetas[key]] = valores;
            }else if( key === "statusSelect"){
                let valores = [];
                for (let transactionStatus of value){
                    valores.push(transactionStatus.value)
                }
                params[etiquetas[key]] = valores
            } else if(key === 'cardType') {
                if(Array.isArray(value)) {
                    let valores = [];
                    for(let types of value) {
                        if(cardTypes[types.toLowerCase()]) {
                            valores.push(cardTypes[types.toLowerCase()])
                        } else {
                            valores.push(types)
                        }
                    }
                    params[etiquetas[key]] = valores;
                }
            } else if(typeof(value) === "number"){
                params[etiquetas[key]] = value
            } else if(typeof(value) === "string"){
                params[etiquetas[key]] = value
            } else if(key === 'columns'){
                let columnas = []
                for(let appC of value) {
                    if(etiquetasColumnas[appC]) {
                        columnas.push(etiquetasColumnas[appC])
                    }
                }
                params[etiquetas[key]] = columnas;
            }  else if(key === 'columnsDefault'){
                let columnas = []
                for(let appC of value) {
                    if(etiquetasColumnas[appC.value]) {
                        columnas.push(etiquetasColumnas[appC.value])
                    }
                }
                params[etiquetas[key]] = columnas;
            } else if(Array.isArray(value)){
                params[etiquetas[key]] = value
            } else {
                params[etiquetas[key]] = value.value;
            }
        }
    }
    return params;
}

const etiquetas = {
    appCode: "application_code",
    commerce: "owner_name",
    currency: "currency",
    payment: "method_type",
    timeZone: "time_zone",
    asc: "asc",
    desc: "desc",
    columns:"columns",
    name: "name",
    transactionID: "carrier_tr_id", 
    email: "email", 
    authCode: "authorization_code", 
    buyerName: "buyer_name", 
    statusTransaction : "status",
    columnsDefault : "columns_default",
    cardType : "card_type",
    cardBin : "card_bin",
    cardNumber : "card_number",
    devReference : "dev_reference",
    carrierCode: "carrier_code",
    statusSelect:"status_back",
    statusDetailSelect : "status_detail",
    userId:"user_id",
}
const etiquetasDates = {
    dateEnd: "created_lt",
    dateStart: "created_gt",
  }

const etiquetasColumnas = {
    isAppCode : "application_code",
    isDate : "created_at",
    isId : "carrier_tr_id",
    isStatus : "status",
    isDetailStatus : "status_detail",
    isAuthCode : "authorization_code",
    isAmount : "amount",
    isCurrency : "currency",
    isPaymentMethod : "payment_method_type",
    isEmail : "buyer_email",
    isCardType : "card_type",
    isCardBin : "card_bin",
    isCardNumber : "card_number",
    isDevReference :  "dev_reference",
    isMessage: "message",
    isCarrierCode: "carrier_code"
}

const cardTypes = {
    "visa" : "vi",
	"mastercard" : "mc",
    "american express" : "ax",
	"diners" : "di",
    "discover" : "dc",
	"elo" : "el",
	"credisensa" : "cs",
	"solidario" : "so",
    "exito" : "ex",
	"alkosto" : "ak",
    "codensa" : "cd",
	"sodexo" : "sx",
	"vrbeneficios" : "vr",
	"jcb" : "jc",
	"aura" : "au"
}
