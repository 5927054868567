import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require('qs');

export const fetchStatusOverTime = (parametros, token) => {
    return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.STATUS_OVER_TIME}`, parametros,token);
};

export const fetchStatusMoney = (parametros, token) => {
  return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.STATUS_MONEY}`, parametros,token);
};

export const fetchStatusReject = (parametros, token, language) => {
  return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.STATUS_REJECT}`, parametros,token, language);
};

export const fetchtransactionCarrier = (parametros, token) => {
  return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.STATUS_CARRIER}`, parametros,token);
};

export const fetchStatusAppCode = (parametros, token) => {
  return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.STATUS_APP_CODE}`, parametros,token);
};

export const fetchTransactionActivity = (parametros, token, ) => {
  return fetchAnalitycs(`${ENDPOINTS.ANALITYCS.TRANSACTION_ACTIVITY}`, parametros,token);
};
export const fetchAnalitycs = (url, parametros, token, language) => {
  const lang ={
    ...parametros,
    language
  }
  return Axios.get(url,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: lang,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};