import { select } from 'redux-saga/effects'; 

import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fetchReportStatus } from "../../services/reportsService";
import { setReportStatus } from "./reportStatusAction";;


export const getAuth = (state) => state.auth;

export function* REPORT_TRANSACTIONS_STATUS({ payload }) {
    yield putAction(setReportStatus({reportLoading : true}));
    yield putAction(setReportStatus({reportStatus : {}}));
    // let params = getParams(payload.data);
    let params = payload.data;
    let reportStatusData = {};
    try {
        const auth = yield select(getAuth);
        const result = yield call(fetchReportStatus, params, auth.token);
        if (result && result.data && result.data.data && result.data.data.values) {
            //reportData = result.data
            reportStatusData = result.data.data.values;
        }
        
    }catch(error){}
    yield putAction(setReportStatus({reportLoading: false}));
    yield putAction(setReportStatus({reportStatus: reportStatusData }));
}

export default function* ReportStatusSaga() {
  yield all([
    takeLatest(ACTION_TYPES.REPORTS.ASYNC.GET_REPORTS_STATUS, REPORT_TRANSACTIONS_STATUS),
  ]);
}

