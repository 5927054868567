import { translate, I18n } from "react-i18nify";
import moment from 'moment';
import { select } from 'redux-saga/effects'; 

import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fetchStatusOverTime, fetchStatusMoney, fetchStatusReject, 
fetchStatusAppCode,fetchtransactionCarrier,fetchTransactionActivity} from "../../services/analitycsService";
import { setTransactionsOverTime, setStatusTransactions, setRejectStatusTransactions, 
setCarrierTransactions, setActivityTransactions, setAppCodeTransactions} from "./analitycsAction";
import { getGlobalValue } from '../../../context/langContext';

export const getAuth = (state) => state.auth;

export function* STATUS_OVER_TIME({ payload }) {
    yield putAction(setTransactionsOverTime({barLoading : true}));
    yield putAction(setTransactionsOverTime({barData : null}));
    const auth = yield select(getAuth);
    let params = getParams(payload.data);
    let labels = [];
    let datosOk = { label: translate("status.ok"),
      backgroundColor: 'rgba(60,179,113,0.8)',
      borderColor: 'rgba(60,179,113,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(50,205,50,0.4)',
      hoverBorderColor: 'rgba(50,205,50,1)',
      tension:0.3,
      data: []
    }
    let datosError = { label: translate("status.error"),
      backgroundColor: 'rgba(255,0,0,0.8)',
      borderColor: 'rgba(255,0,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(240,128,128,0.4)',
      hoverBorderColor: 'rgba(240,128,128,1)',
      tension:0.3,
      data: []
    }
    let datosPending = { label: translate("status.pending"),
      backgroundColor: 'rgba(30,144,255,0.8)',
      borderColor: 'rgba(30,144,255,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(30,144,255,0.4)',
      hoverBorderColor: 'rgba(30,144,255,1)',
      tension:0.3,
      data: []
    }
    try {
        const result = yield call(fetchStatusOverTime, params, auth.token);
        if(result && result.data && result.data.data && result.data.data.values) {
            let jsonAux = {}
            for(let entry of result.data.data.values) {
                if(!labels.includes(entry.date)) {
                    labels.push(entry.date)
                }
                if(!jsonAux[entry.date]){
                    jsonAux[entry.date] = {};  
                }
                let statusS = 'status-'+entry.status
                jsonAux[entry.date][statusS] = entry;
            }
            labels = labels.reverse(); 
            for(let label of labels) {
                // count pendng
                if(jsonAux[label]['status-0']) {
                    datosPending.data.push(jsonAux[label]['status-0'].count)
                } else {datosPending.data.push(0)}

                // count ok
                if(jsonAux[label]['status-1']) {
                    datosOk.data.push(jsonAux[label]['status-1'].count)
                } else {datosOk.data.push(0)}
                // count error
                if(jsonAux[label]['status-4']) {
                    datosError.data.push(jsonAux[label]['status-4'].count)
                } else {datosError.data.push(0)}
            }
        }
    }catch(error){

    }
    yield putAction(setTransactionsOverTime({barLoading : false}));
    yield putAction(setTransactionsOverTime({barData : {labels: labels, datasets:{datosOk,datosError,datosPending} }}));
}

export function* STATUS_MONEY({ payload }) {
    yield putAction(setStatusTransactions({statusBarLoading : true}));
    yield putAction(setStatusTransactions({statusBarData : null}));
    const auth = yield select(getAuth);
    let params = getParams(payload.data);
    
    let datosOk = { label: translate("status.ok"),
      backgroundColor: 'rgba(60,179,113,0.8)',
      borderColor: 'rgba(60,179,113,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(50,205,50,0.4)',
      hoverBorderColor: 'rgba(50,205,50,1)',
      data: []
    }
    let datosError = { label: translate("status.error"),
      backgroundColor: 'rgba(255,0,0,0.8)',
      borderColor: 'rgba(255,0,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(240,128,128,0.4)',
      hoverBorderColor: 'rgba(240,128,128,1)',
      data: []
    }
    let datosPending = { label: translate("status.pending"),
      backgroundColor: 'rgba(30,144,255,0.8)',
      borderColor: 'rgba(30,144,255,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(30,144,255,0.4)',
      hoverBorderColor: 'rgba(30,144,255,1)',
      data: []
    }
    let labels = [];
    try {
        const result = yield call(fetchStatusMoney, params, auth.token);
        let jsonAux = {}
        if(result && result.data && result.data.data && result.data.data.values) {
            for(let entry of result.data.data.values) {
                if(!labels.includes(entry.currency)) {
                    labels.push(entry.currency)
                }
                if(!jsonAux[entry.currency]){
                    jsonAux[entry.currency] = {};  
                }
                let statusS = 'status-'+entry.status
                jsonAux[entry.currency][statusS] = entry;
            }
        }
        for(let money of labels){
            if(jsonAux[money]['status-0']) {
                datosPending.data.push(Math.round(jsonAux[money]['status-0'].sum * 100) / 100)
            } else {datosPending.data.push(0)}

            // count ok
            if(jsonAux[money]['status-1']) {
                datosOk.data.push(Math.round(jsonAux[money]['status-1'].sum * 100) / 100)
            } else {datosOk.data.push(0)}
            // count error
            if(jsonAux[money]['status-4']) {
                datosError.data.push(Math.round(jsonAux[money]['status-4'].sum * 100) / 100)
            } else {datosError.data.push(0)}
        }
    }catch(error){

    }
    yield putAction(setStatusTransactions({statusBarLoading : false}));
    yield putAction(setStatusTransactions({statusBarData : {labels: labels, datasets:{datosOk,datosError,datosPending} }}));
}

export function* STATUS_REJECT({ payload }) {
    const connectionLanguage = getGlobalValue();
    yield putAction(setRejectStatusTransactions({statusRejectLoading : true}));
    yield putAction(setRejectStatusTransactions({statusRejectData : null}));
    
    let params = getParams(payload.data);
    let labels  = [];
    let data = [];
    let backgroundColor =[];
    let hoverBackgroundColor = [];
    try {
        const auth = yield select(getAuth);
        const result = yield call(fetchStatusReject, params, auth.token, connectionLanguage);
        if(result && result.data && result.data.data && result.data.data.values) {
            const auth2 = yield select(getAuth);
            let info = result.data.data.values;
            info.sort(function(a, b) {
                    return b.count - a.count;
                });
            let filtrados =  info;
            if(info.length > 10) {
                filtrados = [];
                let indice = 0;
                let otros = 0;
                info.forEach(function (current) {
                    if(indice < 10) {
                        filtrados.push(current)
                        indice++;
                    } else {
                        otros = otros +current.count;
                    }
                });
                // TODO mover esta etiqueta al translate
                filtrados.push({count: otros, status : "Otros"})
            }
            for(let entry of filtrados) {
                labels.push(getLabelReject(entry.status, auth2.paymentsError));
                data.push(entry.count);
                let r = Math.round(Math.random()*255);
                let g = Math.round(Math.random()*255);
                let b = Math.round(Math.random()*255);
                backgroundColor.push('rgba(' + r + ',' + g + ','+ b+ ', 0.7)')
                hoverBackgroundColor.push('rgba(' + r + ',' + g + ','+ b+ ',0.5)')
            }
        }
    }catch(error){

    }
    yield putAction(setStatusTransactions({statusRejectLoading : false}));
    yield putAction(setStatusTransactions({statusRejectData : {labels: labels, datasets:{data:data, 
    backgroundColor:backgroundColor, hoverBackgroundColor:hoverBackgroundColor} }}));
}

export function* STATUS_CARRIER({ payload }) {
    yield putAction(setCarrierTransactions({transactionCarrierData : null}));
    yield putAction(setCarrierTransactions({transactionCarrierLoading : false}));
    const auth = yield select(getAuth);
    let params = getParams(payload.data);
    let labels  = [];
    let carriers = [];
    let datasets = []
    try {
        const result = yield call(fetchtransactionCarrier, params, auth.token);
        if(result && result.data && result.data.data && result.data.data.values) {
            let jsonAux = {}
            for(let entry of result.data.data.values) {
                if(!labels.includes(entry.date)) {
                    labels.push(entry.date)
                }
                if(!carriers.includes(entry.carrier)) {
                    carriers.push(entry.carrier)
                }
                if(!jsonAux[entry.date]){
                    jsonAux[entry.date] = {};  
                }
                jsonAux[entry.date][entry.carrier] = entry.count;
            }
            labels = labels.reverse();
            const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
            const randomByte = () => randomNumber(0, 255)
            const randomPercent = () => (randomNumber(50, 100) * 0.01).toFixed(2)
            const randomCssRgba = () => `rgba(${[randomByte(), randomByte(), randomByte(), randomPercent()].join(',')})`
            for(let carrier of carriers) {
                let valores = {
                    label : carrier,
                    backgroundColor: randomCssRgba(),
                }
                let data = []
                for(let fecha of labels){
                    if(jsonAux[fecha][carrier]) {
                        data.push(jsonAux[fecha][carrier])
                    } else {
                        data.push(0);
                    }
                }
                valores.data = data;
                datasets.push(valores)
            }
            
        }
    }catch(error){

    }
    yield putAction(setCarrierTransactions({transactionCarrierData : {labels :labels, datasets :datasets}}));
    yield putAction(setCarrierTransactions({transactionCarrierLoading : false}));
}

export function* STATUS_APP_CODE({ payload }) {
    yield putAction(setAppCodeTransactions({statusAppCodeLoading : true}));
    yield putAction(setAppCodeTransactions({statusAppCodeData : null}));
    const auth = yield select(getAuth);
    let params = getParams(payload.data);
    let labels  = [];
    let datosOk = { label: translate("status.ok"),
      backgroundColor: 'rgba(60,179,113,0.8)',
      borderColor: 'rgba(60,179,113,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(50,205,50,0.4)',
      hoverBorderColor: 'rgba(50,205,50,1)',
      data: []
    }
    let datosError = { label: translate("status.error"),
      backgroundColor: 'rgba(255,0,0,0.8)',
      borderColor: 'rgba(255,0,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(240,128,128,0.4)',
      hoverBorderColor: 'rgba(240,128,128,1)',
      data: []
    }
    try {
        const result = yield call(fetchStatusAppCode, params, auth.token);
        if(result && result.data && result.data.data && result.data.data.values) {
            let resultado = result.data.data.values;
            let llaves = Object.keys(result.data.data.values)
            for(let entry of llaves){
                labels.push(entry);
                let ok = 0;
                let reject = 0;
                for(let datos of resultado[entry]) {
                    if(datos.status === "1") {
                        ok = datos.count
                    }
                    if(datos.status === "4") {
                        reject = datos.count
                    }
                }
                datosOk.data.push((ok * 100)/(ok + reject));
                datosError.data.push((reject * 100)/(ok + reject));
            }
        }
    }catch(error){

    }
    yield putAction(setAppCodeTransactions({statusAppCodeLoading : false}));
    yield putAction(setAppCodeTransactions({statusAppCodeData : {labels: labels, datasets:{datosOk,datosError} }}));
}

export function* STATUS_ACTIVITY({ payload }) {
    yield putAction(setActivityTransactions({transactionActivityLoading : true}));
    yield putAction(setActivityTransactions({transactionActivityData : null}));
    const auth = yield select(getAuth);
    let params = getParams(payload.data);
    let labels  = ["00","01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", 
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    let datos = { label: translate("label.transaction"),
      backgroundColor: 'rgba(135,206,235,0.8)',
      borderColor: 'rgba(135,206,235,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(135,206,250,0.4)',
      hoverBorderColor: 'rgba(135,206,250,1)',
      data: []
    }
    try {
        const result = yield call(fetchTransactionActivity, params, auth.token);
        if(result && result.data && result.data.data && result.data.data.values) {
            for(let label of labels) {
                let valor = 0;
                for(let entry of result.data.data.values) {
                    if((label + ':00') === entry.date) {
                        valor = entry.count;
                    }
                }
                datos.data.push(valor);
            }
        }
    }catch(error){

    }
    yield putAction(setActivityTransactions({transactionActivityLoading : false}));
    yield putAction(setActivityTransactions({transactionActivityData : {labels: labels, datasets:[datos] }}));
}

function getLabelReject(status, catalogo) {
    if(catalogo) {
        let lang = I18n["_localeKey"];
        for(let entry of catalogo){
            if(entry.type === status) {
                return entry[lang];
            }
        }
    }
    return status;
}

export default function* AnalitycsSaga() {
  yield all([
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_OVER_TIME, STATUS_OVER_TIME),
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_MONEY, STATUS_MONEY),
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_REJECT, STATUS_REJECT),
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_CARRIER, STATUS_CARRIER),
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_APP_CODE, STATUS_APP_CODE),
    takeLatest(ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_ACTIVITY, STATUS_ACTIVITY),
  ]);
}

function getParams(data) {
    let params = {}
    for (let [key, value] of Object.entries(data)) {
        if(etiquetas[key] && value) {
            if(key === 'dateEnd' || key === 'dateStart') {
                params[etiquetas[key]] = moment(value).format('YYYY-MM-DD HH:mm');
            } else if(key === 'appCode') {
                let valores = [];
                for(let appC of value) {
                    valores.push(appC.value)
                }
                params[etiquetas[key]] = valores;
            } else {
                params[etiquetas[key]] = value.value;
            }
        }
    }
    return params;
}

const etiquetas = {
appCode: "application_code",
commerce: "owner_name",
currency: "currency",
dateEnd: "created_lt",
dateStart: "created_gt",
payment: "method_type",
timeZone: "time_zone",
statusDetailSelect: "status_detail",
}
