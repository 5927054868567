import { ACTION_TYPES } from "../../constant/actionTypes";

// Tipo de comercio (Persona natural y jurídica)
export function getTypeCommerce(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_COMMERCE,
    payload: {
      data
    }
  };
}

export function setRegister(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.TYPE_COMMERCE,
    payload: {
      ...data
    }
  };
}

// Para tipo de documento
export function getTypeDocument(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_DOC,
    payload: {
      data
    }
  };
}

export function setTypeDocument(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.TYPE_DOC,
    payload: {
      ...data
    }
  };
}

// Para seleccionar departamento
export function getListDepto(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_DEPTO,
    payload: {
      data
    }
  };
}

export function setListDepto(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.LIST_DEPTO,
    payload: {
      ...data
    }
  };
}

// Seleccionar listado de ciudades según departamento
export function getListCities(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_CITIES,
    payload: {
      data
    }
  };
}

export function setListCities(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.LIST_CITIES,
    payload: {
      ...data
    }
  };
}

// Obtener cuestionario
export function getQuestions(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_QUESTIONS,
    payload: {
      data
    }
  };
}

export function setQuestions(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.QUESTIONS,
    payload: {
      ...data
    }
  };
}


export function initRegister (data) {
  return {
    type: ACTION_TYPES.REGISTERDB.BASIC.INIT_REGISTER,
    payload: {
      data
    }
  };
}
// Obtener registro de usuario
export function getRegisterUser(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_REGISTER_USER,
    payload: {
      data
    }
  };
}

export function cleanQuestions (data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.CLEAN_QUESTIONS,
    payload: {
      data
    }
  };
}
export function validateQuestions (data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.VALIDATE_QUESTIONS,
    payload: {
      data
    }
  };
}

export function nextStepRegister (data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.NEXT_STEP,
    payload: {
      data
    }
  };
}

export function setRegisterUser(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.WORKER.REGISTER_USER,
    payload: {
      ...data
    }
  };
}


export function getCategory(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_CATEGORY,
    payload: {
      data
    }
  };
}

export function getBilling(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_BILLING,
    payload: {
      data
    }
  };
}

export function getReferido(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_REFERIDO,
    payload: {
      data
    }
  };
}

export function getTypePhone(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_PHONE,
    payload: {
      data
    }
  };
}

export function getBank(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_BANK,
    payload: {
      data
    }
  };
}

export function getTypeAccount(data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_ACCOUNT,
    payload: {
      data
    }
  };
}

export function nextStepRegistro (data) {
  return {
    type: ACTION_TYPES.REGISTERDB.ASYNC.NEXT_STEP_REGISTRO,
    payload: {
      data
    }
  };
}