import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  message: "",
  status: null
};

export const MessagesReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.MESSAGES.ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
