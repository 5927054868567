import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { AuthReducer } from "./auth/authReducers";
import { AnalitycsReducer } from "./analitycs/analitycsReducers";
import { MessagesReducer } from "./messages/messagesReducers";
import ThemeReducer from "./theme/themeReducer";
import { TransactionsReducer } from "./transactions/transactionsReducers";
import { LinkToPayReducer } from './linkToPay/linktopayReducers';
import { ReportsReducer } from "./reports/reportsReducers";
import { ReportStatusReducer } from "./reportStatus/reportStatusReducers";
import { ServerStatusReducer } from "./status/statusReducers";
import { RegisterReducers } from "./register/registerReducers";
//import { LanguageReducer } from "./language/languageReducer";


const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    analitycs: AnalitycsReducer,
    message: MessagesReducer,
    theme: ThemeReducer,
    transactions: TransactionsReducer,
    linktopay: LinkToPayReducer,
    reports: ReportsReducer,
    reportStatus: ReportStatusReducer,
    serverStatus : ServerStatusReducer,
    registerRdb: RegisterReducers,
  });

export default rootReducer;
