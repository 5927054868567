import { ACTION_TYPES } from "../../constant/actionTypes";

export function messagesError(error) {
  return {
    type: ACTION_TYPES.MESSAGES.ERROR,
    payload: {
      ...error
    }
  };
}
