import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader";
import configureStore, {
  history,
  sagaMiddleware,
} from "./config/store/configureStore";
import "./App.scss";
import MainLayout from "./layoutRouter/MainLayout/index";
import rootSaga from "./config/model/rootSaga";
import { StyleComponentConnected } from "./scss/StyleComponent";
import "./utils/Translations";
import { loadReCaptcha } from "react-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "./config/constant/base";
//import translations from "./utils/translations/translations";
import { LangContextTheme } from './context/langContext';

export const store = configureStore();

sagaMiddleware.run(rootSaga);

const App =()=> {
  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY); 
  }, [])
 
    return (
      <Provider store={store}>
        <LangContextTheme>
          <StyleComponentConnected />
          <MainLayout history={history} />
        </LangContextTheme>
      </Provider>
    );
  
}

export default hot(module)(App);
