export const PROCESS_ENV = process.env.NODE_ENV;
if (PROCESS_ENV === "development") console.log(process.env);
export const BASE_SITE = process.env.REACT_APP_PUBLIC_URL;
export const SITE_NAME = process.env.REACT_APP_ADMINV2_SITE_NAME;
export const HOST_SITE_ENDPOINTS = process.env.REACT_APP_ADMINV2_HOST_ENDPOINTS; //"https://paymentez-go.paymentez.com"
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_ADMINV2_RECAPTCHA_SITE_KEY;
export const JWT_SECRET = process.env.REACT_APP_ADMINV2_JWT_SECRET;
export const TIME_IDLE = process.env.REACT_TIME_IDLE ? Number(process.env.REACT_TIME_IDLE) : 20;
export const TIME_TOKEN = process.env.REACT_TIEM_TOKEN ? Number(process.env.REACT_TIEM_TOKEN) : 50;
export const REACT_DASHBOARD_CLIENT = process.env.REACT_APP_DASHBOARD_CLIENT;

export const ENV_THEME = (() => {
  if (process.env.REACT_APP_ADMINV2_THEME) {
    return JSON.parse(process.env.REACT_APP_ADMINV2_THEME.replace(/'/g, '"'));
  } else {
    return {};
  }
})();

export const REACT_QUESTIONS_STG = (() => {
  if (process.env.REACT_APP_QUESTIONS_STG) {
    return JSON.parse(process.env.REACT_APP_QUESTIONS_STG.replace(/'/g, '"'));
  }
})();

export const REACT_RESPONSEQ_STG = (() => {
  if (process.env.REACT_APP_RESPONSEQ_STG) {
    return JSON.parse(process.env.REACT_APP_RESPONSEQ_STG.replace(/'/g, '"'))
  }
})()

export const REACT_ANALYTICS_CODE = process.env.REACT_APP_ANALYTICS_CODE ? process.env.REACT_APP_ANALYTICS_CODE : null;

export const REACT_FACEBOOK_PIXEL_CODE = process.env.REACT_APP_FACEBOOK_PIXEL_CODE ? process.env.REACT_APP_FACEBOOK_PIXEL_CODE : null;

export const REACT_REDEBAN_ENVIRONMENT= process.env.REACT_APP_REDEBAN_ENVIRONMENT ? process.env.REACT_APP_REDEBAN_ENVIRONMENT : "prod";

export const REACT_GOOGLE_TAG_CODE = process.env.REACT_APP_GOOGLE_TAG_CODE ? process.env.REACT_APP_GOOGLE_TAG_CODE : null;
