import { all, takeLatest, put as putAction, call, select } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fetchTypeCommerce, fetchTypeDoc, fetchNameDepto, fetchListCities, fecthListQuestions,
fecthValidateQuestions, fecthRegisterUser, fetchCategory, fetchBilling, fetchTypePhone,
fetchBank, fetchTypeAccount, fetchReferido  } from "../../services/registerRdbService";
import { setRegister } from "./registerAction";
import { REACT_REDEBAN_ENVIRONMENT, REACT_QUESTIONS_STG, REACT_RESPONSEQ_STG } from "../../constant/base";

export const getInfoGral = (state) => state.registerRdb.infoGrl;
export const getCategory = (state) => state.registerRdb.dataCommerceCategory;
export const getInfoComercio = (state) => state.registerRdb.dataCommerce
export const getInfoBank = (state) => state.registerRdb.dataBank

export function* TYPEPERSON({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ typeCommerce: [] }));
  
  try {
      const success = yield call(fetchTypeCommerce);
      yield putAction(setRegister({ typeCommerce: success.data }));
      
    } catch (error) {console.log(error) }
    yield putAction(setRegister({ loadingRegister: false }));
}

export function* TYPEDOC({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ typeDoc: [] }));

  try {
      const success = yield call(fetchTypeDoc);
      yield putAction(setRegister({ typeDoc: success.data }));
      
    } catch (error) {console.log(error) }
    yield putAction(setRegister({ loadingRegister: false }));
}

export function* LISTDEPTO({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ depto: [] }));

  try {
      const success = yield call(fetchNameDepto);
      yield putAction(setRegister({ depto: success.data }));
      
    } catch (error) {console.log(error) }
    yield putAction(setRegister({ loadingRegister: false }));
}

export function* LISTCITIES({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ cities: [] }));

  try {
      const success = yield call(fetchListCities, payload.data);
      yield putAction(setRegister({ cities: success.data }));
      
    } catch (error) {console.log(error) }
    yield putAction(setRegister({ loadingRegister: false }));
}

export function* QUESTIONS({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ questions: {} }));
  yield putAction(setRegister({errorQuestions: ""}));
  yield putAction(setRegister({findQuestions: false}));
  yield putAction(setRegister({infoGrl: payload.data}));
  ///

  if(REACT_REDEBAN_ENVIRONMENT === "development") {
    yield putAction(setRegister({ questions: REACT_QUESTIONS_STG }));
  } else {
      try {
          const success = yield call(fecthListQuestions, payload.data);
          yield putAction(setRegister({ questions: success.data.data.values }));
      } catch (error) {console.log(error)
        yield putAction(setRegister({errorQuestions: "error"}));
      }
  }
  yield putAction(setRegister({findQuestions: true}));
  yield putAction(setRegister({ loadingRegister: false }));
}

export function* INIT_REGISTER({ payload }) {
  yield putAction(setRegister({loadingRegister: false}));
  //yield putAction(setRegister({ questions: {} }));
  yield putAction(setRegister({step: 0}));
  yield putAction(setRegister({errorQuestions: ""}));
  yield putAction(setRegister({findQuestions: false}));
  yield putAction(setRegister({loadValidateQuestions : false}));
}

export function* CLEAN_QUESTIONS({ payload }) {
  yield putAction(setRegister({ questions: {} }));
  yield putAction(setRegister({errorQuestions: ""}));
  yield putAction(setRegister({findQuestions: false}));
  yield putAction(setRegister({loadValidateQuestions : false}));
}

export function* VALIDATE_QUESTIONS({ payload }) {
  yield putAction(setRegister({loadValidateQuestions : true}));
  yield putAction(setRegister({errorQuestions: ""}));
  yield putAction(setRegister({validateQuestions: {}}));
  
  //////
  const info = yield select(getInfoGral);
  let datos = payload.data;
  datos.type_commerce = info.type_commerce
  datos.type_document = info.type_document
  datos.document_number = info.document_number
  datos.date = info.data
  datos.last_name = info.last_name
  datos.phone = info.phone
  datos.state = info.state
  datos.city = info.city
  datos.name = info.name
  datos.second_name = info.second_name
  datos.second_last_name = info.second_last_name
  datos.rut = info.rut
  datos.nit = info.nit
  datos.fiscal_name = info.fiscal_name

  if(REACT_REDEBAN_ENVIRONMENT === "development") {
    yield putAction(setRegister({ validateQuestions: REACT_RESPONSEQ_STG }));
  } else {
    try {
      const success = yield call(fecthValidateQuestions, payload.data);
      yield putAction(setRegister({ validateQuestions: success.data.data }));
    } catch (error) {
      console.log(error)
      yield putAction(setRegister({errorQuestions: "error"}));
    }
  }
  yield putAction(setRegister({ loadValidateQuestions: false }));;
}

export function* NEXT_STEP({ payload }) {
  yield putAction(setRegister({step : payload.data}));
  
}

export function* REGISTER_USER({ payload }) {
  yield putAction(setRegister({loadingRegister: true}));
  yield putAction(setRegister({ user: {} }));
  
  try {
      const success = yield call(fecthRegisterUser, payload.data);
      yield putAction(setRegister({ user: success.data }));

  } catch (error) {console.log(error) }
  yield putAction(setRegister({ loadingRegister: false }));
}

export function* GET_CATEGORY ({ payload }) {
  yield putAction(setRegister({ category: [] }));
  try {
      const success = yield call(fetchCategory);
      yield putAction(setRegister({ category: success.data }));
      
    } catch (error) {console.log(error) }
}
export function* GET_BILLING ({ payload }) {
  yield putAction(setRegister({ billing: [] }));
  try {
      const success = yield call(fetchBilling);
      yield putAction(setRegister({ billing: success.data }));
      
    } catch (error) {console.log(error) }
}
export function* GET_REFERIDO ({ payload }) {
  yield putAction(setRegister({ referido: [] }));
  try {
      const success = yield call(fetchReferido);
      yield putAction(setRegister({ referido: success.data }));
      
    } catch (error) {console.log(error) }
}
export function* GET_TYPE_PHONE ({ payload }) {
  yield putAction(setRegister({ typePhone: [] }));
  try {
      const success = yield call(fetchTypePhone);
      yield putAction(setRegister({ typePhone: success.data }));
      
    } catch (error) {console.log(error) }
}
export function* GET_BANK ({ payload }) {
  yield putAction(setRegister({ bank: [] }));
  try {
      const success = yield call(fetchBank);
      yield putAction(setRegister({ bank: success.data }));
      
    } catch (error) {console.log(error) }
}
export function* GET_TYPE_ACCOUNT ({ payload }) {
  yield putAction(setRegister({ typeAccount: [] }));
  try {
      const success = yield call(fetchTypeAccount);
      yield putAction(setRegister({ typeAccount: success.data }));
      
    } catch (error) {console.log(error) }
}

export function* NEXT_STEP_REGISTRO({ payload }) {
  if(payload.data.step === 1) {
    yield putAction(setRegister({dataCommerceCategory: payload.data}));
    yield putAction(setRegister({step : payload.data.step}));
  } else if(payload.data.step === 3){
    yield putAction(setRegister({dataCommerce : payload.data}));
    yield putAction(setRegister({step : payload.data.step}));
  } else if(payload.data.step === 4){
    yield putAction(setRegister({dataBank : payload.data}));
    yield putAction(setRegister({step : payload.data.step}));
  } else if(payload.data.step === 5){
    yield putAction(setRegister({loadingRegister: true}));
    yield putAction(setRegister({ user: {} }));

    const info = yield select(getInfoGral);
    const categorySelect = yield select(getCategory)
    const comercio = yield select(getInfoComercio);
    const bank = yield select(getInfoBank);
    let datos = {}
    datos.type_commerce = info.type_commerce;
    datos.name = info.name;
    datos.second_name = info.second_name;
    datos.last_name = info.last_name;
    datos.rut = info.rut;
    datos.nit = info.nit;
    datos.fiscal_name = info.fiscal_name;
    datos.type_document = info.type_document;
    datos.document_number = info.document_number;
    datos.date = info.date;
    datos.phone = info.phone;
    datos.state = info.state;
    datos.city = info.city;
    datos.category_code = categorySelect.category_code;
    datos.billing = comercio.billing;
    datos.referido = comercio.referido;
    datos.address = comercio.address;
    datos.type_phone = comercio.type_phone;
    datos.products = comercio.products;
    datos.operador = comercio.operador
    datos.bank = bank.bank;
    datos.account_type = bank.account_type;
    datos.account_number = bank.account_number;
    datos.cedula_url = bank.cedula_url;
    datos.cedula_reverso_url = bank.cedula_reverso_url;
    datos.rut_url = bank.rut_url;
    datos.chamber_commerce_url = bank.chamber_commerce_url;
    datos.captcha = payload.data.token;
    datos.second_last_name = info.second_last_name;
    datos.email = info.email;
    datos.commerce_phone = comercio.commerce_phone;
  try {
      const success = yield call(fecthRegisterUser, datos);
      yield putAction(setRegister({ user: success.data.data }));

    } catch (error) {
      console.log(error)
      yield putAction(setRegister({ user:  {error : "error" }}));
    }
    yield putAction(setRegister({step : payload.data.step}));
    yield putAction(setRegister({ loadingRegister: false }));

  } else if(payload.data.step === 0){
    yield putAction(setRegister({step : payload.data.step}));
    yield putAction(setRegister({loadingRegister: false}));
    yield putAction(setRegister({errorQuestions: ""}));
    yield putAction(setRegister({findQuestions: false}));
    yield putAction(setRegister({loadValidateQuestions : false}));
    yield putAction(setRegister({ questions: {} }));
    yield putAction(setRegister({errorQuestions: ""}));
    yield putAction(setRegister({findQuestions: false}));
    yield putAction(setRegister({loadValidateQuestions : false}));
    yield putAction(setRegister({validateQuestions: {} }));
  } else {
    yield putAction(setRegister({step : payload.data.step}));
  }
  
}
export default function* registerSagas() {
  yield all([
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_COMMERCE, TYPEPERSON),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_DOC, TYPEDOC),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_DEPTO, LISTDEPTO),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_LIST_CITIES, LISTCITIES),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_QUESTIONS, QUESTIONS),
    takeLatest(ACTION_TYPES.REGISTERDB.BASIC.INIT_REGISTER, INIT_REGISTER),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.CLEAN_QUESTIONS, CLEAN_QUESTIONS),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.VALIDATE_QUESTIONS, VALIDATE_QUESTIONS),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.NEXT_STEP, NEXT_STEP),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_REGISTER_USER, REGISTER_USER),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_CATEGORY, GET_CATEGORY),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_BILLING, GET_BILLING),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_REFERIDO, GET_REFERIDO),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_PHONE, GET_TYPE_PHONE),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_BANK, GET_BANK),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.GET_TYPE_ACCOUNT, GET_TYPE_ACCOUNT),
    takeLatest(ACTION_TYPES.REGISTERDB.ASYNC.NEXT_STEP_REGISTRO, NEXT_STEP_REGISTRO),

  ]);
}
