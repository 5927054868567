import { ACTION_TYPES } from "../../constant/actionTypes";

export function linktopayForm(sendValues) { // Acción que uso en el componente para linkToPay dashboard
    return {
        type: ACTION_TYPES.LINKTOPAY.ASYNC.FORM_LINKTOPAY,
        payload: {
            sendValues
        }
    };
}

export function linktopayComplete(sendValues) { // Accion que uso en sagas
    return {
        type: ACTION_TYPES.LINKTOPAY.WORKER.LOAD_FORM,
        payload: {
            ...sendValues
        }
    };
}

// Actions para historial de LTPs
export function getTable(data) {
    return {
        type: ACTION_TYPES.LINKTOPAY.ASYNC.GET_TRANSACTIONS,
        payload: {
            data
        }
    };
}
export function setTable(data) {
  return {
    type: ACTION_TYPES.LINKTOPAY.WORKER.SET_TRANSACTIONS,
    payload: {
      ...data
    }
  };
}
export function setInitTable() {
  return {
    type: ACTION_TYPES.LINKTOPAY.BASIC.INIT
  };
}


// Link to pay masivo
export function getLTPMassive(data) { 
  return {
    type: ACTION_TYPES.LINKTOPAY.ASYNC.LINKTOPAY_MASSIVE,
    payload: data
  };
}

export function setLTPMAssive(data) {
  return {
    type: ACTION_TYPES.LINKTOPAY.WORKER.SET_MASSIVE,
    payload: data
  };
}

// Acción que uso en el componente para linkToPay REDEBAN
export function getFormRedeban(sendValues) {
    return {
        type: ACTION_TYPES.LINKTOPAY.ASYNC.FORM_REDEBAN,
        payload: {
            sendValues
        }
    };
}

export function setFormRedeban(sendValues) {
    return {
        type: ACTION_TYPES.LINKTOPAY.WORKER.SET_REDEBAN,
        payload: {
            ...sendValues
        }
    };
}

// Consulta de detalle con payment_reference
export function getIdLTP(data) {
  return {
    type: ACTION_TYPES.LINKTOPAY.ASYNC.PAYMENT_REFERENCE,
    payload: {data}
  };
}

export function setIdLTP(data) {
  return {
    type: ACTION_TYPES.LINKTOPAY.WORKER.SET_PAYMENT_REFERENCE,
    payload: {...data}
  };
}

export function initIdLTP() {
  return {
    type: ACTION_TYPES.LINKTOPAY.BASIC.INIT_PAYMENT_REFERENCE
  }
}
