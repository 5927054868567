import { ACTION_TYPES } from "../../constant/actionTypes";

export function getReportStatus(data) {
  return {
    type: ACTION_TYPES.REPORTS.ASYNC.GET_REPORTS_STATUS,
    payload: {
      data
    }
  };
}

export function setReportStatus(data) {
  return {
    type: ACTION_TYPES.REPORTS.WORKER.SET_REPORTS_STATUS,
    payload: {
      ...data
    }
  };
}
