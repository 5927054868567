import { all, takeEvery, put as putAction, call } from "redux-saga/effects";
//import { notification } from "antd";

import { ACTION_TYPES } from "../../constant/actionTypes";

import { messagesError } from "../messages/messagesActions";
import { fecthTheme } from "../../services/themeService";
import { themeCurrent } from "./themeActions";

export function* themeSagaGet() {
  try {
    const success = yield call(fecthTheme, {"domain" : window.location.origin });
    yield putAction(themeCurrent(success.data));
  } catch (error) {
    yield putAction(messagesError(error));
  }
}

export default function* ThemeSaga() {
  yield all([
    themeSagaGet(),
    takeEvery(ACTION_TYPES.THEME.ASYNC.GET, themeSagaGet)
    //takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    //takeEvery(actions.LOGOUT, LOGOUT),
    //LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
