import { ACTION_TYPES } from "../../constant/actionTypes";

export function getTransactionsOverTime(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_OVER_TIME,
    payload: {
      data
    }
  };
}

export function setTransactionsOverTime(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_OVER_TIME,
    payload: {
      ...data
    }
  };
}

export function getStatusTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_MONEY,
    payload: {
      data
    }
  };
}

export function setStatusTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_MONEY,
    payload: {
      ...data
    }
  };
}

export function getRejectStatusTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_REJECT,
    payload: {
      data
    }
  };
}

export function setRejectStatusTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_REJECT,
    payload: {
      ...data
    }
  };
}

export function getCarrierTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_CARRIER,
    payload: {
      data
    }
  };
}

export function setCarrierTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_CARRIER,
    payload: {
      ...data
    }
  };
}

export function getAppCodeTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_APP_CODE,
    payload: {
      data
    }
  };
}

export function setAppCodeTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_APP_CODE,
    payload: {
      ...data
    }
  };
}

export function getActivityTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.ASYNC.GET_STATUS_ACTIVITY,
    payload: {
      data
    }
  };
}

export function setActivityTransactions(data) {
  return {
    type: ACTION_TYPES.ANALITYCS.WORKER.SET_STATUS_ACTIVITY,
    payload: {
      ...data
    }
  };
}