import { select } from "redux-saga/effects";
import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fecthLinktoPay } from "../../services/linkToPayService";
import { fecthLTPRedeban } from "../../services/linkToPayService";
import { fetchLTPMassive } from "../../services/linkToPayService";
import { getParams } from "../transactions/transactionsSagas";
import { fetchTableTransactions } from "../../services/linkToPayService";
import { fetchDetailsPaymentReference } from "../../services/linkToPayService";
import { linktopayComplete, setTable, setIdLTP } from "./linktopayAction";
import { getGlobalValue } from '../../../context/langContext';

export const getAuth = (state) => state.auth;

export function* FORM_PAYMENT({ payload }) {
  const { sendValues } = payload;
  yield putAction(linktopayComplete({ formValues: {} }));
  yield putAction(linktopayComplete({ formLoading: true }));

  try {
    const auth = yield select(getAuth);
    const success = yield call(fecthLinktoPay, sendValues, auth.token);
    yield putAction(linktopayComplete({ formValues: success }));
  } catch (error) {}
  yield putAction(linktopayComplete({ formLoading: false }));
}

export function* FORM_REDEBAN({ payload }) {
  const { sendValues } = payload;
  yield putAction(linktopayComplete({ formLoading: true }));
  yield putAction(linktopayComplete({ formRdb: {} }));

  try {
    const auth = yield select(getAuth);
    const success = yield call(fecthLTPRedeban, sendValues, auth.token);
    yield putAction(linktopayComplete({ formRdb: success.data }));
  } catch (error) {}
  yield putAction(linktopayComplete({ formLoading: false }));
}

export function* FORM_FILE({ payload: data }) {
    yield putAction(linktopayComplete({ formFile: {} }));
    yield putAction(linktopayComplete({formLoading: true}));
    
    try {
        const auth = yield select(getAuth);
        const success = yield call(fetchLTPMassive, data, auth.token);
        yield putAction(linktopayComplete({ formFile: success }));

    } catch (error) { }
    yield putAction(linktopayComplete({ formLoading: false }));
}

export function* TABLE_TRANSACTIONS({ payload }) {
  const connectionLanguage = getGlobalValue();
  yield putAction(setTable({ tableLoading: true }));
  yield putAction(setTable({ dataTransactions: {} }));
  let params = getParams(payload.data, "linkToPay");
  let tableData = {};
  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchTableTransactions, params, auth.token, connectionLanguage);
    if (result && result.data && result.data.data && result.data.data.values) {
      tableData = result.data.data.values;
    }
  } catch (error) {}
  yield putAction(setTable({ tableLoading: false }));
  yield putAction(setTable({ dataTransactions: tableData }));
}

export function* ID_LINKTOPAY({ payload }) {
  const connectionLanguage = getGlobalValue();
  yield putAction(setIdLTP({ loadingIdLinkToPay: true }));
  yield putAction(setIdLTP({ idDetail: {} }));
  let id = payload.data.id;
  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchDetailsPaymentReference, id, auth.token, connectionLanguage);
    if (result && result.data && result.data.carrier_tr_id) {
      yield putAction(setIdLTP({ idDetail: result.data}));
    }
  } catch (error) {
    console.log(error);
  }
  yield putAction(setIdLTP({ loadingIdLinkToPay: false }));
}

export default function* linktopaySagas() {
  yield all([
    takeLatest(ACTION_TYPES.LINKTOPAY.ASYNC.FORM_LINKTOPAY, FORM_PAYMENT),
    takeLatest(ACTION_TYPES.LINKTOPAY.ASYNC.FORM_REDEBAN, FORM_REDEBAN),
    takeLatest(ACTION_TYPES.LINKTOPAY.ASYNC.LINKTOPAY_MASSIVE, FORM_FILE),
    takeLatest(ACTION_TYPES.LINKTOPAY.ASYNC.GET_TRANSACTIONS, TABLE_TRANSACTIONS),
    takeLatest(ACTION_TYPES.LINKTOPAY.ASYNC.PAYMENT_REFERENCE, ID_LINKTOPAY),
  ]);
}
