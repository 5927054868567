import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  statusLoading: false,
  statusData: {}
};

export const ServerStatusReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.STATUS.WORKER.SET_SERVER_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};