import React from "react";
import { connect } from "react-redux";
import Color from "color";

/**
 *
 * @param {redux} param0 Theme is the only object we really need. Theme can only contain images and colors.
 * Other types of attributes should be considered somewhere else
 */
export const StyleComponent = ({ theme }) => {

  const primaryColor = Color(theme?.primary_color || "#20a8d8").hex();
  const secondaryColor = Color(theme?.secondary_color || "#c8ced3").hex();
  const successColor = Color(theme?.success_color || "#e6ffe9").hex();
  const warningColor = Color(theme?.warning_color || "#ff9933").hex();
  const dangerColor = Color(theme?.danger_color || "#f86c6b").hex();
  const cancelColor = Color(theme?.cancel_color || "#f86c6b").hex();
  const infoColor = Color(theme?.info_color || "#63c2de").hex();
  const grayColor = Color(theme?.gray_color || "#73818f").hex();
  const darkColor = Color(theme?.dark_color || "#2f353a").hex();

  const getContrasColor = (color) => {
    return Color(color).luminosity() < 0.6 ? "white" : "black"
  }

  const colors = `
    :root {
      --primary: ${primaryColor};
      --secondary: ${secondaryColor};
      --success: ${successColor};
      --warning: ${warningColor};
      --danger: ${dangerColor};
      --cancel: ${cancelColor};
      --info: ${infoColor};
      --gray: ${grayColor};
      --gray-dark: ${darkColor};
    }
  `;

  const background = `
  .bg-primary{
    background-color: ${theme?.primary_color}!important;
  }
  .card.bg-primary{
    border-color: ${Color(theme.primary_color).darken(0.5).hex()}
  }
  .bg-light{
    background-color: ${theme?.primary_color}!important;
    color: ${theme?.primary_color_text_over};
  }
  .bg-light.text-muted{
    color: ${theme?.primary_color_text_over}!important;
  }
  .card{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over}
  }
  body{
    background-color: ${theme?.background_color_body}!important;
  }
  `;

  const sidebar = `
  .sidebar { 
    background: ${theme?.side_bar_color}; 
    box-shadow: ${theme?.side_bar_shadow};
  }
  .sidebar .nav-link .nav-icon { color: ${theme?.side_bar_color_text_over} }
  .sidebar .nav-link:hover .nav-icon { color: ${theme?.side_bar_color_text_over
    } }
  .sidebar .nav-link { color: ${theme?.side_bar_color_text_over}}
  .sidebar .nav-link:hover { background: ${Color(theme.side_bar_color_over)
      .darken(0.3)
      .hex()}
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {background: ${Color(
        theme.side_bar_color_over
      )
      .darken(0.3)
      .hex()}}

  .sidebar .sidebar-minimizer { background: ${Color(theme.side_bar_color_over)
      .darken(0.1)
      .hex()}!important;
  }
  .sidebar .sidebar-minimizer:hover { background: ${Color(
        theme.side_bar_color_over
      )
      .darken(0.3)
      .hex()}!important;
  }
  .sidebar .nav-title {
    background-color: ${theme?.side_bar_color};
    border-color: ${theme?.side_bar_color};
    color: ${Color(theme.side_bar_color_text_over).darken(0.1).hex()};
  }
  
  /** ACTIVE **/
  .sidebar .nav-link.active .nav-icon { color: ${theme?.side_bar_color_active_text_over
    }}
  .sidebar .nav-link.active { 
    border-left: 0.3rem solid ${theme?.primary_color};
    background: ${theme?.side_bar_color_active};
    color: ${theme?.side_bar_color_active_text_over}
  }
  `;

  const buttons = `
  .btn-primary {
    background-color: ${theme?.primary_color};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .btn-primary.disabled, .btn-primary:disabled, .btn-primary.disabled:hover {
    background-color: ${theme?.primary_color};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .btn-primary:hover {
    background-color: ${Color(theme.primary_color).darken(0.2).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .btn-outline-primary{
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color};
  }
  .btn-outline-primary:hover {
    background-color: ${Color(theme.primary_color).darken(0.2).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary.disabled:hover {
    border-color: ${theme?.primary_color};
    color: ${Color(theme.primary_color).darken(-0.5).hex()};
    background-color: ${theme?.primary_color_text_over};
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle{
    background-color: ${Color(theme.primary_color).darken(0.2).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }

  .btn-outline-secondary{
    border-color: ${theme?.secondary_color};
    color: ${theme?.secondary_color};
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle{
    background-color: ${Color(theme.secondary_color).darken(0.2).hex()};
    border-color: ${theme?.secondary_color};
    color: ${theme?.secondary_color_text_over};
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: ${Color(theme.primary_color).darken(0.2).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }

  .btn-link, a {
    color: ${theme?.primary_color};
  }

  .btn-link:hover, a:hover{
    color: ${Color(theme.primary_color).darken(0.2).hex()};
  }
  .text-primary {
    color: ${theme?.primary_color}!important;
  }
  `;

  const badge = `
  .badge {
    border: 1px solid;
  }
  .badge-initiated, .bg-initiated, .card.bg-initiated
  {
    background-color: var(--gray)!important;
    border-color: color-mix(in srgb, var(--gray) 90%, black)!important;
    color:${getContrasColor(grayColor)}!important;
    color: ${theme?.gray_color_text_over || getContrasColor(grayColor)}!important;

  }
  .badge-warning, .bg-warning, .card.bg-warning,
  .badge-pending, .bg-pending, .card.bg-pending
  {
    background-color: var(--warning)!important;
    border-color: color-mix(in srgb, var(--warning) 90%, black)!important;
    color: ${theme?.warning_color_text_over || getContrasColor(warningColor)}!important;

  }
  .badge-success, .bg-success, .card.bg-success,
  .badge-approved, .bg-approved, .card.bg-approved
  {
    background-color: var(--success)!important;
    border-color: color-mix(in srgb, var(--success) 90%, black)!important;
    color: ${theme?.success_color_text_over || getContrasColor(successColor)}!important;
  }
  .badge-cancelled, .bg-cancelled, .card.bg-cancelled
  {
    background-color: var(--cancel)!important;
    border-color: color-mix(in srgb, var(--cancel) 90%, black)!important;
    color: ${theme?.cancel_color_text_over || getContrasColor(cancelColor)}!important;
    
    background-color: var(--gray-dark)!important;
    border-color: color-mix(in srgb, var(--gray-dark) 90%, black)!important;
    color: ${theme?.cancel_color_text_over || "white"}!important;

  }
  .badge-danger, .bg-danger, .card.bg-danger,
  .badge-rejected, .bg-rejected, .card.bg-rejected 
  {
    background-color: var(--danger)!important;
    border-color: color-mix(in srgb, var(--danger) 90%, black)!important;
    color: ${theme?.danger_color_text_over || getContrasColor(dangerColor)}!important;

  }
  .badge-dark, .bg-dark, .card.bg-dark,
  .badge-expired, .bg-expired, .card.bg-expired {
    background-color: var(--gray)!important;
    border-color: color-mix(in srgb, var(--gray) 90%, black)!important;
    color: ${theme?.gray_color_text_over || getContrasColor(grayColor)}!important;
  }
  .badge-info, .bg-info, .card.bg-info {
    background-color: var(--info)!important;
    border-color: color-mix(in srgb, var(--info) 90%, black)!important;
    color: ${theme?.info_color_text_over || getContrasColor(infoColor)}!important;

  }
  .badge-primary, .bg-primary, .card.bg-primary {
    background-color: var(--primary)!important;
    border-color: color-mix(in srgb, var(--primary) 90%, black)!important;
    color: ${theme?.primary_color_text_over || getContrasColor(primaryColor)}!important;
  }
  .badge-secondary, .bg-secondary, .card.bg-secondary {
    background-color: var(--secondary)!important;
    border-color: color-mix(in srgb, var(--secondary) 90%, black)!important;
    color: ${theme?.secondary_color_text_over || getContrasColor(secondaryColor)}!important;
  }

  .callout{
    border-left-color: ${theme?.primary_color};
  }
  .callout-over{
    border-left-color: ${theme?.primary_color_text_over}!important;
  }
  .callout-info{
    border-left-color: ${theme?.info_color}!important;
  }
  .callout-danger{
    border-left-color: ${theme?.danger_color}!important;
  }
  .callout-success{
    border-left-color: ${theme?.success_color}!important;
  }
  .callout-warning{
    border-left-color: ${theme?.warning_color}!important;
  }
  .callout-secondary{
    border-left-color: ${theme?.secondary_color}!important;
  }

  .list-group-item-accent-info{
    border-left: 4px solid ${theme?.info_color};
  }
  .list-group-item-accent-danger{
    border-left: 4px solid ${theme?.danger_color};
  }
  .list-group-item-accent-success{
    border-left: 4px solid ${theme?.success_color};
  }
  .list-group-item-accent-warning{
    border-left: 4px solid ${theme?.warning_color};
  }
  .list-group-item-accent-secondary{
    border-left: 4px solid ${theme?.secondary_color};
  }
  `;

  const dropdown = `
  .dropdown-header{
    background-color: ${Color(theme.primary_color).darken(0.5).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .dropdown-menu{
    border: 1px solid ${Color(theme.primary_color).darken(0.5).hex()}
  }
  `;

  const table = `
  .table .thead-light th{
    background-color: ${Color(theme.primary_color).darken(0.4).hex()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }

  .table-hover tbody tr:hover{
    background-color: ${Color(theme.primary_color).alpha(0.8).string()};
    border-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .page-item.active .page-link {
    color: ${theme?.primary_color_text_over};
    background-color: ${theme?.primary_color};
    border-color: ${theme?.primary_color};
  }
  .page-link {
    color: ${theme?.primary_color};
    background-color: ${theme?.primary_color_text_over};
    border: 1px solid ${Color(theme.primary_color).darken(-0.1).hex()};
  }
  .page-item.disabled .page-link {
    color: ${Color(theme.primary_color).darken(-0.1).hex()};
    border-color: ${Color(theme.primary_color).darken(-0.1).hex()};
  }
  .page-link:hover {
    color: ${theme?.primary_color};
    background-color: ${Color(theme.primary_color).darken(-0.2).hex()}22;
    border-color: ${Color(theme.primary_color).darken(-0.1).hex()};
  }
  `;

  const footer = `
  .app-footer{
    background-color: ${theme?.secondary_color};
    color: ${theme?.secondary_color_text_over};
  }
  `;

  const header = `
  .app-header{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over};
  }
  .breadcrumb{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over};
  }
  `;

  const aside = `
  .aside-menu{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over};
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over};
  }

  .aside-menu .nav-tabs .nav-link.active{
    background-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over}
  }
  .tab-content{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over}
  }

  .list-group-item{
    background-color: ${theme?.primary_color_alt};
    color: ${theme?.primary_color_alt_text_over}
  }
  .list-group-item-action:hover, .list-group-item-action:focus{
    background-color: ${Color(theme.primary_color).alpha(0.2).string()};
  }
  `;

  const switches = `
  .switch-success .switch-input:checked + .switch-slider{
    background-color: ${theme?.success_color};
    border-color: ${Color(theme.success_color).darken(0.2).hex()};
  }
  `;

  const selects = `
  .select-value-filter {
    border: 1px solid ${theme?.primary_color};
  }
  .select-value-filter-icon {
    border-right: 1px solid ${Color(theme.primary_color).darken(-0.5).hex()};
  }
  .react-select__multi-value {
    background-color: ${Color(theme.primary_color).lighten(0.2).hex()}11;
    color: ${Color(theme.primary_color).darken(0.3).hex()};
    border: 1px solid ${theme?.primary_color};
  }
  .react-select__multi-value__remove {
    border-left: 1px solid ${Color(theme.primary_color).darken(-0.5).hex()};
  }
  .react-select__control .react-select__control--is-focuse{
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgb(000/8%), 0 0 0 3px rgb(0 126 255 /10%);
    background #fff; 
  }
  .react-select__multi-value__remove:hover{
    background-color: rgba(0,113,230,.08);
    color: #0071e6;
  }

  .daterangepicker .ranges li.active {
    background-color: ${theme?.primary_color};
    color: ${theme?.primary_color_text_over};
  }
  .daterangepicker td.in-range {
    background-color: ${Color(theme.primary_color).lighten(0.2).hex()}22;
  }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: ${theme?.primary_color}!important;
    border-color: transparent;
    color: ${theme?.primary_color_text_over};
  }
  `;

  return (
    <>
      {/** Navbar */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
          /** BACKGROUND */
          ${background}
        
          /** VARS */
        ${colors}

        /** SIDEBAR */
        ${sidebar}

        /** BUTTONS */
        ${buttons}

        /** BADGE */
        ${badge}

        /** DROPDOWNS */
        ${dropdown}

        /** TABLE */
        ${table}

        /** FOOTER */
        ${footer}

        /** HEADER */
        ${header}

        /** ASIDE */
        ${aside}

        /** SWITCHES */
        ${switches}
        /** SWITCHES */
        ${selects}
`,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: { ...state.theme },
});

export const StyleComponentConnected = connect(mapStateToProps)(StyleComponent);
