import moment from "moment";
import { select } from "redux-saga/effects";
import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import {
  fetchTableTransactions,
  fetchRefundTransaction,
  fetchLogTransaction,
  fetchTransactionPdfFunction,
  fetchstatusHistory
} from "../../services/transactionService";
import { setTable, setRefund, setLog } from "./transactionsAction";
import { getGlobalValue } from '../../../context/langContext';

export const getAuth = (state) => state.auth;

export function* TABLE_TRANSACTIONS({ payload }) {
  const connectionLanguage = getGlobalValue();
  yield putAction(setTable({ tableLoading: true }));
  yield putAction(setTable({ dataTransactions: {} }));
  let params = getParams(payload.data);
  // let params = payload.data;
  let tableData = {};
  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchTableTransactions, params, auth.token, connectionLanguage);
    if (result && result.data && result.data.data && result.data.data.values) {
      tableData = result.data.data.values;
    }
  } catch (error) {}
  yield putAction(setTable({ tableLoading: false }));
  yield putAction(setTable({ dataTransactions: tableData }));
}
export function* REFUND_TRANSACTION({ payload }) {
  yield putAction(setRefund({ refundLoading: true }));
  yield putAction(setRefund({ refundMsg: null }));
  let id = payload.data.id;
  const amount = payload.data.amount;

  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchRefundTransaction, id, amount, auth.token);
    if (result && result.data && result.data.msg && result.data.msg === "ok") {
      yield putAction(setRefund({ refundMsg: "ok" }));
    } else {
      yield putAction(setRefund({ refundMsg: "error" }));
    }
  } catch (error) {
    console.log(error);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.Msg
    ) {
      if (
        error.response.data.Msg ===
          "The partial refund is not supported by carrier" ||
        error.response.data.Msg === "allow_partial_refund"
      ) {
        yield putAction(setRefund({ refundMsg: "errorPartialRefund" }));
      } else {
        yield putAction(setRefund({ refundMsg: "error" }));
      }
    } else {
      yield putAction(setRefund({ refundMsg: "error" }));
    }
  }
  yield putAction(setRefund({ refundLoading: false }));
}
export function* LOG_TRANSACTION({ payload }) {
  yield putAction(setLog({ logLoading: true }));
  yield putAction(setLog({ logData: {} }));
  let id = payload.data.id;
  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchLogTransaction, id, auth.token);
    if (result && result.data && result.data.values) {
      yield putAction(setLog({ logData: result.data.values }));
    }
  } catch (error) {
    console.log(error);
  }
  yield putAction(setLog({ logLoading: false }));
}
export function* STATUS_HISTORY({payload}){
  const connectionLanguage = getGlobalValue();
  yield putAction(setLog({ logLoading: true }));
  yield putAction(setLog({ logData: {} }));
  let id = payload.id;
  try {
    const auth = yield select(getAuth);
    const result = yield call (fetchstatusHistory, id, auth.token, connectionLanguage);
    if(result && result.data){
    yield putAction(setLog({ logData: result }));
  }
}catch (error) {
  console.log(error);
}
yield putAction(setLog({logLoading: false}));

}
export function* PDF_TRANSACTION({payload}){
  const connectionLanguage = getGlobalValue();
 let params = {time_zone:payload.data.time_zone.value};
 let id = payload.data.id;
 try{
  const auth = yield select(getAuth);
  const result = yield call(fetchTransactionPdfFunction,id,params,auth.token, connectionLanguage )
  let resObj ={
    filename:`${id}.pdf`,
    blob:result.request.response
  }
  const newBlob = new Blob([resObj.blob], {type: 'application/pdf'})
  if(window.navigator && window.navigator.msSaveOrBlob){
    window.navigator.msSaveBlob(newBlob, resObj.filename)
  } else {
    const URL= window.URL ||window.MozURL || window.webkitURL ||window.MSURL || window.OURL
    const anchor = document.createElement('a')
    anchor.href = URL.createObjectURL(newBlob)
    anchor.download = resObj.filename
    document.body.appendChild(anchor)
    anchor.target = '_blank'
    anchor.click()
    document.body.removeChild(anchor)
  }
 }catch(error){
 console.log(error)
 }
}
export default function* TransactionsSaga() {
  yield all([
    takeLatest(
      ACTION_TYPES.TRANSACTIONS.ASYNC.GET_TRANSACTIONS,
      TABLE_TRANSACTIONS
    ),
    takeLatest(
      ACTION_TYPES.TRANSACTIONS.ASYNC.GET_REFUND_TRANSACTION,
      REFUND_TRANSACTION
    ),
    takeLatest(
      ACTION_TYPES.TRANSACTIONS.ASYNC.GET_LOG_TRANSACTION,
      LOG_TRANSACTION
    ),
    takeLatest(
      ACTION_TYPES.TRANSACTIONS.ASYNC.GET_STATUS_HISTORY,
      STATUS_HISTORY
    ),
    takeLatest(
      ACTION_TYPES.TRANSACTIONS.ASYNC.GET_PDF_TRANSACTION,
      PDF_TRANSACTION
    ),
  ]);
}

export function getParams(data, type) {
  const typeData = data.statusDate.value
  function changeValue(valor){
    etiquetasDates.dateEnd=`${valor}_lt`;
    etiquetasDates.dateStart=`${valor}_gt`;
  Object.assign(etiquetas, etiquetasDates);
  }
  changeValue(typeData)
 
  let params = {};
  for (let [key, value] of Object.entries(data)) {
    if (etiquetas[key] && value) {
     if (key === "dateEnd" || key === "dateStart") {
        params[etiquetas[key]] = moment(value).format(
          type === "linkToPay" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
        );
      } else if (key === "appCode") {
        let valores = [];
        for (let appC of value) {
          valores.push(appC.value);
        }
        params[etiquetas[key]] = valores;
      }else if ( key === "statusSelect"){
        let valores = [];
        for (let transactionStatus of value){
          valores.push(transactionStatus.value)
        }
        params[etiquetas[key]] =valores

      }else if (key === "cardType") {
        if (Array.isArray(value)) {
          let valores = [];
          for (let types of value) {
            if (cardTypes[types.toLowerCase()]) {
              valores.push(cardTypes[types.toLowerCase()]);
            } else {
              valores.push(types);
            }
          }
          params[etiquetas[key]] = valores;
        }
      } else if (typeof value === "number") {
        params[etiquetas[key]] = value;
      } else if (typeof value === "string") {
        params[etiquetas[key]] = value;
      } else if (Array.isArray(value)) {
        params[etiquetas[key]] = value;
      } else {
        params[etiquetas[key]] = value.value;
      }
    }
  }
  return params;
}
const etiquetas = {
  appCode: "application_code",
  commerce: "owner_name",
  currency: "currency",
  statusDate:"status_date",
  payment: "method_type",
  timeZone: "time_zone",
  limit: "limit",
  page: "page",
  asc: "asc",
  desc: "desc",
  transactionID: "carrier_tr_id",
  email: "email",
  authCode: "authorization_code",
  buyerName: "buyer_name",
  statusTransaction: "status",
  cardType: "card_type",
  cardBin: "card_bin",
  cardNumber: "card_number",
  devReference: "dev_reference",
  carrierCode: "carrier_code",
  userId: "user_id",
  statusSelect: "status_back",
  //LINKTOPAY SEARCH TYPE
  user_dashboard: "user_dashboard",
  user_email: "user_email",
  statusDetailSelect: "status_detail",
};
const etiquetasDates = {
  dateEnd: "created_lt",
  dateStart: "created_gt",
}
const cardTypes = {
  visa: "vi",
  mastercard: "mc",
  "american express": "ax",
  diners: "di",
  discover: "dc",
  elo: "el",
  credisensa: "cs",
  solidario: "so",
  exito: "ex",
  alkosto: "ak",
  codensa: "cd",
  sodexo: "sx",
  vrbeneficios: "vr",
  jcb: "jc",
  aura: "au",
};
