import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  reportLoading: false,
  reportMessages: null
};

export const ReportsReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.REPORTS.WORKER.SET_REPORTS:
      return { ...state, ...action.payload };
    case ACTION_TYPES.REPORTS.BASIC.INIT:
      return { ...initialState };
    default:
      return state;
  }
};