import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";

export const fecthTheme = data => {
  return Axios.post(
    `${ENDPOINTS.THEME.GET}`,
    { ...data },
    {
      headers: new Headers({
        //"Auth-Token": getAuth(),
        "Content-Type": "application/json"
      }),
      credentials: "same-origin"
    }
  );
};
