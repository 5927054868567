import { ENDPOINTS } from "../constant/endPoints";
import { REACT_DASHBOARD_CLIENT } from "../constant/base";
import Axios from "axios";
var qs = require("qs");

export const fetchTable = (url, parametros, token, language) => {
  let paramsFixed = {
    ...parametros,
    app_code: parametros.application_code,
    language:language
  };

  return Axios.get(url, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    params: paramsFixed,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const fecthLinktoPay = (request, token) => {
  return Axios.post(
    `${ENDPOINTS.LINKTOPAY.FORM_LINKTOPAY}`,
    {
      app_code: request.app_code,
      user: request.user,
      order: request.order,
      configuration: request.configuration,
    },
    {
      headers: {
        Authorization: "Bearer ".concat(token),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fecthLTPRedeban = (request, token) => {
  return Axios.post(
    `${ENDPOINTS.LTPREDEBAN.LTP_REDEBAN}`,
    { app_code: request.app_code, client: REACT_DASHBOARD_CLIENT },
    {
      headers: {
        Authorization: "Bearer ".concat(token),
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchLTPMassive = (datos, token) => {
	return Axios.post(
		`${ENDPOINTS.LINKTOPAY.LINKTOPAY_MASSIVE}`,
		datos,
		{
			headers: {
				"Authorization": 'Bearer '.concat(token),
				'Content-Type': 'multipart/form-data'
			}
		}
	);
}

export const fetchTableTransactions = (parametros, token, language) => {
  return fetchTable(`${ENDPOINTS.LINKTOPAY.FORM_LINKTOPAY}`, parametros, token, language);
};

export const  fetchDetailsPaymentReference = (id, token, language) => {
  return fecthLinktoPayFunction(`${ENDPOINTS.LINKTOPAY.DETAILS_PAYMENT_REFERENCE}` + id, id,token,language)
}

export const fecthLinktoPayFunction = (url, parametros, token, language) => {
  let paramsLanguage = {
    language: language
  };
  return Axios.get(url,
    {
      headers: {
        "Authorization": 'Bearer '.concat(token),
        'Content-Type': 'multipart/form-data'
      },
      params: paramsLanguage,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
}


