import React from "react";
import Loadable from "react-loadable";
import Loading from "../pages/Loading";

export const lazyLoadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loading />
  });
