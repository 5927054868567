import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  tableLoading: false,
  statusTable: null,
  dataTransactions: {},
  refundLoading :false,
  refundMsg : null,
  logLoading :false,
  logData : null,
};

export const TransactionsReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.TRANSACTIONS.WORKER.SET_TRANSACTIONS:
      return { ...state, ...action.payload };
    case ACTION_TYPES.TRANSACTIONS.BASIC.INIT:
      return { ...initialState };
    case ACTION_TYPES.TRANSACTIONS.ASYNC.GET_PDF_TRANSACTION:
      return {...initialState}

    case ACTION_TYPES.TRANSACTIONS.WORKER.SET_REFUND_TRANSACTION:
      return { ...state, ...action.payload };
    case ACTION_TYPES.TRANSACTIONS.BASIC.INIT_REFUND_TRANSACTION:
      return { ...state, ...{refundLoading :false, refundMsg : null} };

    case ACTION_TYPES.TRANSACTIONS.WORKER.SET_LOG_TRANSACTION:
      return { ...state, ...action.payload };
      case ACTION_TYPES.TRANSACTIONS.ASYNC.GET_STATUS_HISTORY:
        return {...state, ...action.payload};
    case ACTION_TYPES.TRANSACTIONS.BASIC.INIT_LOG_TRANSACTION:
      return { ...state, ...{logLoading :false, logData : null} };
    default:
      return state;
  }
};