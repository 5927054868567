import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import NotFoundPage from "../../views/Pages/Page404/Page404";
import { MAIN_ROUTERS } from "../../config/router/mainRouters";
import { AuthRouterConnected as AuthRouter } from "../../pages/components/AuthRouter";
import { REACT_DASHBOARD_CLIENT } from "../../config/constant/base";
class MainRouter extends React.Component {
  isRedeban = () => {
    return REACT_DASHBOARD_CLIENT && REACT_DASHBOARD_CLIENT === "redeban";
  };

  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <AuthRouter
            exact
            path="/"
            render={() =>
              this.isRedeban() ? (
                <Redirect to="/linkToPay-create" />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
            // render={() =>
            //   this.isRedeban() ? (
            //     <Redirect to="/linktopay" />
            //   ) : (
            //     <Redirect to="/transaction" />
            //   )
            // }
          />
          {MAIN_ROUTERS.map((route) => {
            return (
              <AuthRouter
                {...route}
                path={route.path}
                component={route.component}
                key={route.path}
                exact={(() =>
                  typeof route.exact === "undefined" ? false : route.exact)()}
              />
            );
          })}
          <AuthRouter component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default MainRouter;
